<template>
  <div class="flex md:h-screen">
    <div
      class="relative flex w-1/2 items-center justify-center overflow-hidden bg-primary-600 bg-left bg-no-repeat max-md:hidden"
      :style="`background-image: url('${bgImage}')`"
    >
      <img
        class="absolute -left-24 top-4 hidden max-h-60 xl:block"
        src="@/assets/img/banner_symbol_2.svg"
        alt=""
        loading="lazy"
      >

      <img
        class="absolute -right-20 -top-32 hidden max-h-60 xl:block"
        src="@/assets/img/banner_symbol_3.svg"
        alt=""
        loading="lazy"
      >

      <div class="flex w-5/6 flex-col gap-y-7">
        <div class="w-52 pb-2">
          <slot name="logo">
            <Logo white />
          </slot>
        </div>
        <div class="flex flex-col gap-y-3 text-white">
          <slot name="text" />
        </div>
        <div class="flex items-center gap-x-6">
          <StarsRating
            :value="5"
            fill-color="text-amber-400"
          />
          <BaseIcon
            v-if="ratingsCount === null"
            class="mx-2 text-gray-300"
            icon="fal fa-spinner-third"
            :spin="true"
          />
          <i18n-t
            v-else-if="ratingsCount >= 5000"
            keypath="pages.login.info.ratings"
            tag="p"
            class="text-sm text-gray-300"
          >
            <span class="font-semibold text-white">{{ formatNumber(ratingsCount) }}</span>
          </i18n-t>
        </div>
      </div>

      <img
        v-if="showPersonImages"
        class="absolute bottom-0 right-10 z-10 hidden xl:block xl:max-h-80"
        src="@/assets/img/banner_man_1.png"
        alt=""
        loading="lazy"
      >

      <img
        v-if="showPersonImages"
        class="absolute -bottom-20 right-2 z-20 hidden xl:block xl:max-h-48"
        src="@/assets/img/banner_symbol_2.svg"
        alt=""
        loading="lazy"
      >

      <img
        v-if="showPersonImages"
        class="absolute -bottom-12 right-52 hidden xl:block xl:max-h-80"
        src="@/assets/img/banner_symbol_3.svg"
        alt=""
        loading="lazy"
      >

      <slot name="footer" />
    </div>
    <div class="relative max-md:w-full max-md:pt-4 md:w-1/2">
      <div class="mb-5 px-4 md:hidden">
        <Logo mobile-version-disabled />
      </div>
      <slot />
      <div class="absolute right-4 top-4 cursor-pointer rounded border border-gray-300 px-2 py-1">
        <BaseDropdown>
          <div>
            <span class="mr-2">{{ localeFlags[locale] }}</span>
            <BaseIcon
              icon="fal fa-chevron-down"
              size="xs"
            />
          </div>
          <template #content>
            <BaseDropdownItem
              v-for="item in locales"
              :key="(item as LocaleObject).code"
              @click="switchLanguage((item as LocaleObject).code)"
            >
              <span class="mr-2">{{ localeFlags[(item as LocaleObject).code] }}</span>
              {{ languageTranslation[item.code] }}
            </BaseDropdownItem>
          </template>
        </BaseDropdown>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { LocaleObject } from '@nuxtjs/i18n'
import { useBaseStore } from '~/stores/base-store'
import bgImage from '@/assets/img/earth_background.png'

useHead({
  meta: [{ name: 'viewport', content: 'width=device-width, initial-scale=1' }],
})

const { locales, locale } = useI18n()
const { languageTranslation } = useLanguageTranslation()

const { $apiV1 } = useNuxtApp()
const ratingsCount = ref<number | null>(null)
const fetchRatingsCount = async () => {
  const { data } = await $apiV1.get('benefit-stats/info')
  ratingsCount.value = data.data.happyUserRatingCurrent
}

const switchLanguage = (lang: string) => {
  useBaseStore().language = lang
  window.location.reload()
}

const showPersonImages = computed<boolean>(() => {
  return window.innerHeight >= 820
})

onMounted(() => {
  fetchRatingsCount()
})
</script>
